.header {
    height: 100%;
    position: relative;
    min-height: 600px;
    // background: url(../imgs/header.jpg) no-repeat center center fixed;
    // background-size: cover;

    .swiper {
        width: 100%;
        height: 100%;
      }
      
      .swiper-slide {
        color: #000;
        background: #fff;
        text-align: center;
        font-size: 18px;
      
        /* Center slide text vertically */
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .subtitle {
            margin-top: -20px;
            font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1200 - 320)));
            font-weight: 300;
        }

        .title {
            font-size: 54px;
            font-size: calc(30px + (90 - 60) * ((100vw - 320px) / (1200 - 320)));   
            // margin-bottom: 40px;        
        }
        
        .margin {
            margin-top: 24px;
            margin-bottom: 24px;
        }

        .intro-banner {
            background-image: url("../imgs/banner-img2.jpg");
            width: 100%;
            height: 100%;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .buttons-container {
                display: flex;
                gap: 16px;

               
            }
        }       

        .banner-container {
            background-image: url("../imgs/banner-img2.jpg");
            width: 100%;
            height: 100%;
            background-size: cover;
            display: flex;
            align-items: center;

            .banner-info-container {
                display: flex;
            }

            .banner-logo-container {
                flex: 1;   
                display: flex;
                flex-direction: column;
                padding-left: 20px;
                justify-content: space-between;
                .banner-logo {
                    width: 150px;
                }

                .banner-info {
                    height: 123px;
                    text-align: start;
                    padding-left: 4px;
                    // margin-top: 64px;
                }

                .banner-buttons {
                    text-align: start;
                    margin-bottom: 16px;
                }

                .banner-button {
                    min-width: 118px;
                }
            }
    
            .banner {
                flex: 1;
            }

            .banner-mob-info-container {
                display: none;
            }



        }

       
       
        .brand-logo {
            display: flex;
            align-items: center;
            justify-content: start;
            margin-bottom: 8px;
            max-width: 1000px;
            
            .brand-logo-text {
              font-family: "Russo One", sans-serif;
              font-size: 26px;
              color: red;
              font-weight: bold;
            }
        }


        .mt {
            margin-top: 0;
        }

      }
      
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      
    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($black,.45);
    }

    @include media-breakpoint-down(md) {

        .button {
            min-width: 100px;
            font-size: 14px;
        }

        .banner-logo {
            width: 120px !important;
        }

        .banner-container { 

            .banner-info-container {
                display: none !important;
            }

            .banner-logo-container {
                display: none
            }
    
            .banner-mob-info-container {
                display: block !important;
            }   

            .banner-mob-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
            }

            .banner-mob-info {
                display: flex;
                flex-direction: column;
                gap: 72px;
                align-items: center;
            }

            .banner {
                width: 200px;
            }

            .banner-mob-desc {
                // width: 200px;
                text-align: center;
            }

            .banner-mob-button {
                min-width: 80px;
                font-size: 14px;
                height: 20px;
            }

        }

    }

    @include media-breakpoint-down(sm) {
        .overlay {

            .subtitle {
                margin-top: 0;
            }
        }

        .banner {
            // width: 200px;
        }
    }

    &-title {
        font-size: 2.4rem;
        font-weight: bold;
        opacity: .8;
    }

    // header mini
    &-mini {
        min-height: 24rem;
        height: 24rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 1rem;
        background: lighten($primary, 25%) !important;
    }
}