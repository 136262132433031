.widget {
    position: relative;
    display: flex;
    ;
    justify-content: space-between;
    flex-wrap: wrap;

    .widget-item {
        @include circle(180);
        border: 2px solid $primary;
        text-align: center;
        padding-top: 40px;
        position: relative;

        &:after {
            content: "\e649";
            font-family: 'themify';
            font-size: 12px;
            color: $primary;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -30%;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }



        i {
            color: $primary;
            font-size: 40px;
            display: block;
            margin-bottom: 25px;
        }
    }

    @include media-breakpoint-down(sm) {
        justify-content: space-around;

        .widget-item {
            margin: 15px;

            &::after {
                display: none;
            }
        }


    }
}

// Portfolio wrapper
.img-wrapper {
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 30px;
    display: block;
    display: block;

    img {
        position: relative;
        width: 100%;
        z-index: 8;
    }

    .overlay {
        background: darken(rgba($primary, .7), 10%);
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 100%;
        z-index: 9;
        visibility: hidden;
        opacity: 0;
        @include transition(all, .4s);

        .overlay-infos {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include transition(all, .3s);
            transition-delay: .2s;
            color: $white;

            h5 {
                margin-bottom: 15px;
            }

            a {
                display: inline-block;
                @include circle(35);
                border: 2px solid $white;
                line-height: 35px;
                text-align: center;
                color: $white;
                text-decoration: none;

                &:hover {
                    background: $white;
                    color: $primary;
                }
            }
        }
    }

    &:hover,
    &:active {

        .overlay {
            top: 0;
            visibility: visible;
            opacity: 1;

            .overlay-infos {
                top: 50%;
            }
        }
    }
}


/* testmonial wrapper */
.testimonial-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .img-holder {
        min-width: 45px;
        max-width: 45px;
        z-index: 999;
    }

    img {
        background: $white;
        padding: 4px;
        width: 90px;
        height: 90px;
        border-radius: 50%;
    }

    .body {
        text-align: left;
        border: 1px solid $border-color;
        background: $white;
        padding: 20px 20px 20px 60px;
        border-radius: $card-border-radius;

        .title {
            margin-top: 20px;
            font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1300 - 300)));
        }
    }
}



// Box
.box {
    background: $white;
    border-radius: $border-radius-lg;
    display: flex;
    ;
    align-items: center;
    padding: 30px 0;
    width: 95%;
    max-width: 1050px;
    margin: auto;
    box-shadow: 2px 0 10px rgba($gray-800, .2);
    margin-top: 40px;

    z-index: 99;
    position: relative;
    max-height: 300px;

    .box-item {
        flex-grow: 1;
        flex-basis: 0;
        border-right: 1px solid $border-color;
        padding: 10px 15px;

        i {
            font-size: 50px;
            color: $primary;
            display: inline-block;
            margin-bottom: 25px;
            font-weight: 100;
        }

        .box-title {
            font-size: 18px;
            margin-bottom: 20px;
        }

        &:last-child {
            border: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-top: 50px;
        margin-bottom: 50px;
        flex-direction: column;
        height: auto;
        max-height: none;
        box-shadow: none;
        padding: 0;


        .box-item {
            border: 1px solid $border-color !important;
            border-radius: $card-border-radius;
            padding: 30px;
            margin-bottom: 30px;
        }
    }
}

// Widget Type 2
.widget-2 {
    position: relative;
    display: flex;
    ;

    .widget-item {
        border-right: 1px solid rgba($white, .1);
        padding: 15px;
        text-align: center;
        width: calc(100%/4);
        margin-top: 40px;
        margin-bottom: 20px;

        &:last-child {
            border-right: 0;
        }

        &:hover {
            i {
                transform: translateY(-5px) scale(1.02);
            }
        }

        i {
            margin-top: -40px;
            font-size: calc(30px + (70 - 30) * ((100vw - 320px) / (1200 - 320)));
            display: block;
            margin-bottom: 30px;
            color: $primary;
            @include transition(all, .3);
        }

        .title {
            font-size: 40px;
            font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1200 - 320)));
            margin-bottom: 0px;
        }

        .subtitle {
            font-size: 17px;
            font-size: calc(13px + (17 - 13) * ((100vw - 320px) / (1200 - 320)));
            font-weight: 300;
            margin-bottom: -40px;
        }
    }

    @include media-breakpoint-down(xs) {
        flex-wrap: wrap;

        .widget-item {
            width: 50% !important;
            margin: 0;

            i {
                margin-top: 0;
            }

            .subtitle {
                margin-bottom: 15px;
            }

            &:nth-child(2) {
                border-right: 0 !important;
            }
        }
    }
}



.card {

    img.inset {
        display: block !important;
        -webkit-transform: translateY(5%);
        -moz-transform: translateY(5%);
        transform: translateY(5%);
        width: 90%;
        margin: auto;
        border-radius: $card-border-radius;
        box-shadow: 1px 3px 8px rgba($gray-700, .2);

        &+.card-body {
            margin-top: 10px;
        }
    }

    .card-body .socials {

        a {
            display: inline-block;
            text-decoration: none;
            font-size: 20px;
            margin: 10px 8px 0;
        }
    }
}





// Blog Post Card
.card.blog-post {
    position: relative;
    background: $white;

    img {
        position: relative;
        z-index: 10;
        width: 90%;
        margin: -8% auto 0;
        border-radius: $card-border-radius !important;
        box-shadow: 2px 5px 35px rgba($gray-500, .3), -2px -5px 10px rgba($gray-500, .3);
    }

    .card-body {
        margin: auto;
        background: inherit;
        border-radius: $card-border-radius $card-border-radius 0 0;

        .details {
            text-align: center;

            a {
                text-align: center;
                display: inline-block;
                color: $body-color;
                opacity: .6;
                text-decoration: none;

                &:first-child {
                    margin-right: 30px;
                    margin-left: -3px;
                }

                i {
                    font-size: 18px;
                    position: relative;
                    top: 1px;
                    display: inline-block;
                    width: 25px;
                }
            }
        }
    }
}


// Contact Wrapper
.contact-card {
    border-radius: $border-radius;
    border: 1px solid $border-color;
    background: $white;
    box-shadow: 1px 3px 10px rgba($gray-500, .4);
    padding: 40px 0;
    display: flex;

    .infos {
        padding: 0 40px;
        min-width: 300px;
        width: 100%;

        .contact-details {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
        }

        .item {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            padding-bottom: 20px;
            width: 100%;

            h5 {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 3px;
            }

            i {
                display: block;
                font-size: 30px;
                color: $primary;
                margin: 4px 20px 0 0;
            }
        }

        @include media-breakpoint-down(md) {
            .contact-details {
                display: flex;
                flex-direction: column;
            }
            .item {
                justify-content: left;
            }
        }
        
    }
}