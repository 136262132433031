body,
html { height: 100% }
 

section {
    padding: $section-padding-y $section-padding-x;

    &:nth-child(odd) {
        background-color: lighten($gray-100, 1%);
    }

    &.py-sm,
    .py-sm {
        padding: 30px 0 !important;
    }

    &.py-md,
    .py-md {
        padding: 60px 0 !important;
    }

    &.has-bg-img {
        // background: rgba($gray-900, .95);
        // position: relative;
        // background-color: #fbfcfc;
        // color: $white;

        // &:after {
        //     content: '';
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     right: 0;
        //     bottom: 0;
        //     background-image: url(../imgs/section.jpg);
        //     background-position: center center;
        //     background-attachment: fixed;
        //     background-repeat: no-repeat;
        //     background-size: cover;
        //     z-index: -1;
        // }

        // &.bg-img-1:after {
        //     background-image: url(../imgs/section.jpg);
        // }
        // &.bg-img-2:after {
        //     background-image: url(../imgs/header.jpg);
        // }

    }

    .section-subtitle {
        font-size: calc(17px + (23 - 17) * ((100vw - 320px) / (1200 - 320)));
        font-weight: 300;
        margin-bottom: 5px;
        opacity: .6;
        
        @include media-breakpoint-up(lg) {
            font-size: 23px;
        }
    }

    .section-title {
        font-size: calc(28px + (35 - 28) * ((100vw - 320px) / (1200 - 320)));

        @include media-breakpoint-up(lg) {
            font-size: 35px;
        }
    }

    // .brand-logo {
    //     display: flex;
    //     align-items: center;
    //     justify-content: start;
    //     margin-bottom: 8px;
    //     max-width: 1000px;
        
    //     .brand-logo-text {
    //       font-family: "Russo One", sans-serif;
    //       font-size: 26px;
    //       color: red;
    //       font-weight: bold;
    //     }
    // }

    .product-section {
        width: 100%;
        padding: 16px 24px;
        border-radius: 20px;

        .product-container {
            display: flex;
            gap: 8px;
            

            .product-info {
                text-align: start;
                width: 100%;
                padding-left: 4px;
            }

            .product-header {
                display: block;
                color: #000;
                font-size: 20px;
                margin: 8px 0;
            }

            ul li {
                font-size: 16px;
                // color: #000;
                list-style: none;
            }

            .production-image-container {
                box-shadow: 2px 0 10px rgba(52, 58, 64, 0.2);
                // width: 100%;
                border-radius: 10px;
                overflow: hidden;
                align-self: flex-start;
                img {
                    width: 100%;
                    background-size: cover;
                }
            }
        }
    }

    .height {
        min-height: 320px;
    }
}

/* about */
.about-wrapper {
    margin-left: 70px;
    position: relative;
    
    .content {
        background-color: $white;
        @include custom-box-shadow(blur,$gray-500,$gray-600,.3,.3);
        border-radius: $card-border-radius;
        padding: 30px;
        position: relative;
    }

    .after {
        position: absolute;
        top: 10%;
        left: -60px;
        background: $primary;
        @include custom-box-shadow(blur,$gray-500,$gray-600,.3,.3);         
        width: 200px;
        height: 80%;
        border-radius: $card-border-radius;
        h1 {
            transform: rotate(-90deg);
            position: absolute;
            top: 43%;
            left: -45px;
            font-size: 35px;
        }
    }

}

@include media-breakpoint-down(sm) {
    .about-wrapper {
        margin-left: 0;

        .after {
            display: none;
        }
    }

    .product-container { 
        flex-direction: column;
    }
} 


@include media-breakpoint-down(md) {
    .about-wrapper {
        margin-left: 0;

        .after {
            display: none;
        }
    }

    .product-container { 
        flex-direction: column;
    }
} 