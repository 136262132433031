// Custom Navigation
.custom-navbar {
	min-height: 70px;
	position: fixed;
	top: 30px;
	// background: $white;
	top: 0;
	// margin: auto;
	width: 100%;
	max-width: 100%;
	border: 1px solid rgba($border-color, .15);
	border-radius: 0;
	// border-bottom: 1px solid $gray-200;
	z-index: 9999;
	@include transition(all, .33s);
	padding: 0 !important;
	// background: #fff;

	.nav-child {
		padding: 1rem 1rem;
		// background: #fff;

	}

}

.nav-link {
	color: $gray-700 !important;

	margin: 0 5px;
	font-weight: 600;
	font-size: 14px;
	color: $gray-700 !important;

	&.active {
		color: $primary !important;

	}
}

@include media-breakpoint-down(sm) {
	// .navbar-toggler {
	// 	display: none;
	// }

	.img-logo {
		width: 200px;
	}
}	