.footer {
    padding: 85px 0 70px 0;

    .logo {
        padding-bottom: 6px;

        img {
            width: 35px;
        }
        h6 {
            display: inline-block;
            color: $white;
            position: relative; 
            top: 3px;
            left: 5px;
        }
    }


    .footer-lists {
        display: flex;
        justify-content: space-around;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    .list {
        list-style-type: none;
        padding-left: 0;
        padding: 0 30px;
        padding-bottom: 0 !important;
    }

    .list-head {
        border-bottom: 1px solid rgba($gray-300,.2);
        padding-bottom: 6px;
        margin-bottom: 10px;
    }

    .list-body {
        padding-top: 6px;

        p  {
            margin-bottom: 5px;

            i {
                display: inline-block;
                width: 20px;
            }

        }
            

      

        .social-links {
            margin-top: 25px;

            a {
                display: inline;
                padding: 0 13px 0 0;
                font-size: 20px;
            }
        }

            
    } 

    .menu li {
        list-style: none;
        padding: 3px 0;
    }

    .menu .icon {
        background: lightgray;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
    }


    a {
        color: #495057;
        font-weight: 500;
        text-transform: capitalize;
        text-decoration: none;

        &:hover {
       
        }
    }


    .footer-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    ul {
        display: flex;
        gap: 16px;
    }


}


.footer-bottom {
    background-color: lighten($gray-100, 1%);
    padding: 20px 0;
}

.footer-bottom p {
    margin-bottom: 0;
}

@include media-breakpoint-down(sm) {
    .footer {
        display: block;

        .list {
            width: 90%;
            margin: auto;
            margin-bottom: 45px;
        }
    }
}

@include media-breakpoint-down(md) {
    .footer {
        .list {
            width: 80%;
        }
    }
}