.btn {

	&.rounded {
		border-radius: 50px !important;
	}

	&.btn-outline-light {
		border-color: rgba($white,.4);
	}

	&.w-sm {
		min-width: 80px;
	}

	&.w-md {
		min-width: 110px;
	}

	&.w-lg {
		min-width: 170px;
	}
}

.btn-lg {
	font-size: $font-size-base;
}

// Play control Button
.play-control {
	padding: 0 0 0 10px;
	@include circle(100);
	line-height: 106px;
	border-width: 3px;
	font-size: 60px;
}